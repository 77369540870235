export interface EmptyScreenProps extends React.ComponentPropsWithoutRef<'div'> {
  title: string;
  description?: string;
  hx?: 'h1' | 'h2' | 'h3';
}

export function EmptyScreen({ title, description, hx, children, ...rest }: EmptyScreenProps) {
  const Title = hx ?? 'h1';
  return (
    <div className="flex min-h-screen items-center justify-center" {...rest}>
      <div className="max-w-prose text-center">
        <Title className="mb-2 text-2xl">{title}</Title>
        {description ? <p className="mb-4">{description}</p> : null}
        {children}
      </div>
    </div>
  );
}
